<template>
  <div>
    <p class="font-bold mt-3 text-lg" style="margin-left: 20px">School Registrations</p>
    <div style="padding: 20px;">
      <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
        :highlight-current-row="false" :highlight-selection-row="false" :header-cell-class-name="'custom-head-cell'"
        :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
        <el-table-column prop="project.project_ename" label="Program"></el-table-column>
        <el-table-column label="Status" width="120px">
          <template slot-scope="scope">
            {{ scope.row.project.project_status == 'active' ? 'Ongoing' : 'Completed' }}
          </template>
        </el-table-column>
        <el-table-column label="Advisor" width="150px">
          <template slot-scope="scope">
            <div v-for="(contact, index) in scope.row.contacts" :key="index">
              <p>{{ formatContactName(contact) }} </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="project.project_ename" label="Operation">
          <template slot-scope="scope">
            <el-button type="danger" v-if="user_type != 'advisor'" @click="showDialog(scope.row)">Assign
              Advisor</el-button>
            <el-button type="danger" v-if="scope.row.project.project_status == 'inactive'"
              @click="gotoViewStudentResults(scope.row)">View Results</el-button>
            <el-button type="danger" v-if="scope.row.project.project_status == 'active'"
              @click="goStudentInfo(scope.row)">Manage Students</el-button>
            <el-button v-if="scope.row.project.project_status == 'active'" type="danger"
              @click="showCopyLink(scope.row)">Registration Link</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="isShowDialog" title="Assign Advisor" width="600px" @close="closeDialog">
      <div>
        <el-form :inline="true" ref="form">
          <el-form-item label="Select a program advisors">
            <el-select v-model="selectContactIds" placeholder="Please select" :multiple="true" style="width: 400px;">
              <el-option v-for="(contact, index) in teacherOpt" :key="index" :label="formatContactName(contact)"
                :value="contact._id.$id">
              </el-option>
            </el-select>
          </el-form-item>
          <p>*Emails to students for the project will also go to the assigned program advisor.</p>
        </el-form>
        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button size="medium" @click="isShowDialog = false">Cancel</el-button>
          <el-button type="primary" size="medium" @click="saveInfo()">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isShowCopyDialog" title="Student Registration Link" width="50%">
      <div class="content">
        <div style="padding: 0 10px">Please copy and share the link with your student for registration</div>
        <div class="linkbox" style="display: flex;width: 100%">
          <span class="link">{{ linkStr }}</span>
          <el-button type="primary" size="medium" @click="copyLink">Copy</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  updateAccountApplicationContacts,
  getContactsByAccount,
  getSchoolRegistrations,
} from '../../api/eae'
import {
  getUserId,
  getUserType,
  getAccountId,
  setTargetType
} from '../../utils/store'
export default ({
  name: 'index',
  data() {
    return {
      user_type: getUserType(),
      dataList: [],
      isShowDialog: false,
      teacherOpt: [],
      rowData: {},
      isShowCopyDialog: false,
      linkStr: '',
      selectContactIds: []
    }
  },
  mounted() {
    this.fetchData()
    this.getSubjectList()
    setTargetType('student')
  },
  methods: {

    gotoViewStudentResults(row) {
      this.$router.push({
        path: '/home/studentResults',
        query: {
          project:row.project.project_ename,
          project_id: row.project._id.$id,
          account_id: getAccountId()
        }
      })
    },

    formatContactName(contact) {
      if (contact.contact_enName) {
        return contact.contact_enName
      }
      return contact.contact_lastName + ' ' + contact.contact_givenName
    },

    getSubjectList() {
      this.teacherOpt = []
      getContactsByAccount(getAccountId()).then((res) => {
        if (res.data.length > 0) {
          this.teacherOpt = res.data
        }
      })
    },
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getSchoolRegistrations(getAccountId(), 'student').then((res) => {
        this.dataList = res.data
        loading.close()
      })
    },
    tableCellstyle() {
      return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
    },

    convertTimeToEng(time) {
      var date = new Date(time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ", " + year;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },
    showDialog(data) {
      this.rowData = data
      this.selectContactIds = []
      this.isShowDialog = true
    },

    closeDialog() {
    },
    saveInfo() {
      if (this.selectContactIds.length <= 0) {
        this.$message.warning('Please select advisors')
        return
      }
      let oldContacts = [];
      // if (this.rowData.application_contacts && this.rowData.application_contacts.length > 0) {
      //   oldContacts = this.rowData.application_contacts
      // }
      let newContacts = this.selectContactIds.concat(oldContacts)
      //合并之后，
      const uniqueContacts = [...new Set(newContacts)]
      updateAccountApplicationContacts(this.rowData._id.$id, uniqueContacts).then((res) => {
        this.$message.success('Assign Advisor Successful')
        this.isShowDialog = false
        this.fetchData()
      })
    },
    goStudentInfo(data) {
      this.$router.push({
        path: '/home/manageStudentList',
        query: {
          project_id: data.project_id,
          project_code: data.project.project_code,
          project_name: data.project_name
        }
      })
    },
    showCopyLink(data) {
      let user_type = getUserType()
      if (user_type == 'advisor') {
        let contact_id = getUserId()
        this.linkStr = 'https://my.eae.international/#/form/registration?account_id=' + data.account_id + '&contact_id=' + contact_id + '&project_code=' + data.project.project_code
      } else {
        this.linkStr = 'https://my.eae.international/#/form/registration?account_id=' + data.account_id + '&project_code=' + data.project.project_code
      }
      this.isShowCopyDialog = true
    },
    copyLink() {
      let that = this
      navigator.clipboard.writeText(this.linkStr)
        .then(function () {
          that.isShowCopyDialog = false
          that.$message.success('Copy Success');
        })
        .catch(function (err) {
          console.error('Failed to copy text: ', err);
        });
    },
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
  background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
  font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
  background: var(--c-primary);
  color: white;
  text-align: center;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: Ubuntu-Bold;
}

.linkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  gap: 10px;

  .link {
    flex: auto;
    display: inline-block;
    padding: 0 10px;
    line-height: 36px;
    background: #f2f2f2;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>